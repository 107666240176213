import React from "react";
import dayjs from "dayjs";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider, notification } from "antd";
import { HelmetProvider } from "react-helmet-async";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import router from "router/router";
import handleError from "utils/handler/error";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SuspenseLoading from "components/loading/suspenseLoading/SuspenseLoading";

dayjs.extend(relativeTime);

notification.config({
  duration: 2,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404 ||
          error?.response?.status === 440
        ) {
          return false;
        }
        return failureCount < 1;
      },
      onError: (error) => handleError(error),
    },
    mutations: {
      onError: (error) => handleError(error),
    },
  },
});

export default function App() {
  return (
    <React.Suspense fallback={<SuspenseLoading />}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6b9368",
            fontFamily: "'Sofia Pro', sans-serif",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
            >
              <RouterProvider router={router} />
            </GoogleOAuthProvider>
          </HelmetProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ConfigProvider>
    </React.Suspense>
  );
}
